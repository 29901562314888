<template>
  <div class="campaign_administration">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <div class="left">
          <v-btn class="ml-2 mr-2 m_brown" icon @click="goBack()">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
          </v-btn>
          <div>
            <h1>Administration de {{ $cap(campaign.name) }}</h1>
          </div>
        </div>
      </div>
    </div>
    <v-card class="TableCard" flat>
      <v-card-title>
        <h3>Membres</h3>
      </v-card-title>
      <v-card-text>
        <div class="search-row">
          <div class="search-field">
            <v-text-field
              v-model="memberSearch"
              append-icon="mdi-magnify"
              :label="$t('search_member')"
              single-line
              clearable
              hide-details
              dense
            ></v-text-field>
          </div>
          <div class="add-field">
            <medium-button-slot
              v-if="$hasRight('campaigns.addMember')"
              @click="openUserCampaignCreationModal()"
              >{{ $t("add_member") }}</medium-button-slot
            >
          </div>
        </div>
        <v-data-table
          :loading="members === []"
          :headers="membersHeaders"
          :items="members"
          :items-per-page="10"
          :search="memberSearch"
          class="elevation-1"
          :hide-default-footer="members.length < 11"
        >
          <template v-slot:item.edit="{ item }">
            <div class="table-actions">
              <v-tooltip top v-if="$hasRight('users.edit')">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="openUserEditModale(item)"
                    class="table-action"
                    color="#e89005"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pen</v-icon
                  >
                </template>
                <span>Modifier l'utilisateur</span>
              </v-tooltip>
              <v-tooltip top v-if="$hasRight('campaigns.removeMember')">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="openUserCampaignDeleteModale(item)"
                    class="table-action"
                    color="#b80000"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-account-minus</v-icon
                  >
                </template>
                <span>Retirer de la campagne</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.isTeamMember="{ item }">
            <v-checkbox
              @change="updateUser(item)"
              color="#2a7221"
              v-if="$hasRight('campaigns.updateUserRights')"
              v-model="item.isTeamMember"
            />
          </template>
          <template v-slot:item.readOnly="{ item }">
            <v-checkbox
              color="#2a7221"
              @change="updateUser(item)"
              v-if="$hasRight('campaigns.updateUserRights')"
              v-model="item.readOnly"
            />
          </template>
          <template v-slot:item.customer="{ item }">
            {{ isMainCustomer(item.customer) ? "Client principal" : $t(item.customer.job) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <user-campaign-creation-modale
      v-if="isOpenedUserCampaignCreateModale"
      :campaign="campaign"
      :organization="customerOrganization"
      :members="members"
      @closePopin="isOpenedUserCampaignCreateModale = false"
      @userAdded="refreshViewData()"
    />
    <RemoveUserModale
      v-if="isOpenedUserRemoveModale"
      :user="currentUser"
      @userDeleted="refreshViewData"
      @closePopin="isOpenedUserRemoveModale = false"
    />
    <EditUserModale
      v-if="isOpenedUserEditModale"
      :user="currentUser"
      @closePopin="isOpenedUserEditModale = false"
      @userEdited="refreshViewData"
    />
  </div>
</template>

<script>
import UserCampaignCreationModale from "@/components/entities/campaign/UserCampaignCreation.modal";
import RemoveUserModale from "@/components/entities/campaign/RemoveUserCampaign.modal.vue";
import EditUserModale from "@/components/entities/user/EditUser.modal.vue";

export default {
  components: {
    UserCampaignCreationModale,
    RemoveUserModale,
    EditUserModale,
  },
  data() {
    return {
      isOpenedUserCampaignCreateModale: false,
      isOpenedUserRemoveModale: false,
      isOpenedUserEditModale: false,
      campaign: {
        name: "",
      },
      currentUser: {},
      memberSearch: "",
      membersHeaders: [
        { text: "Prénom", value: "customer.firstname" },
        { text: "Nom", value: "customer.lastname" },
        { text: "Métier", value: "customer" },
        { text: "Membre de l'équipe", value: "isTeamMember", align: "start" },
        { text: "Lecture seule", value: "readOnly", align: "start" },
        { text: "Modifier l'utilisateur", value: "edit", align: "center" },
      ],
      members: [],
    };
  },
  methods: {
    openUserEditModale(user) {
      this.currentUser = user.customer;
      this.isOpenedUserEditModale = true;
    },
    isMainCustomer(user) {
      return user.roles && user.roles.includes("ROLE_CUSTOMER_MAIN");
    },
    async updateUser(member) {
      await this.$api.campaigns.editMemberShip(
        this.$route.params.id,
        member.customer.id,
        member
      );
      this.refreshViewData();
    },
    goBack() {
      this.$router.back();
    },
    refreshViewData() {
      if (undefined === this.$store.state.user.current?.userId)
        return setTimeout(this.refreshViewData, 200);

      this.getCampaign();
      this.getMembersData();
    },
    async getMembersData() {
      const userCampaigns = await this.getUserCampaigns();

      this.members = userCampaigns
        .filter((uc) => {
          if (this.isInternal()) return true;
          else return uc.customer.isInternal === false;
        });
    },
    async getUserCampaigns() {
      return await this.$api.campaigns.getUserCampaigns(this.$route.params.id);
    },
    async getCampaignMembers() {
      return await this.$api.campaigns.getUserCampaigns(
        this.$route.params.id,
        !this.isInternal() ? false : null
      );
    },
    async getCampaign() {
      this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
    },
    isInternal() {
      return false === this.$isRoleAbove("ROLE_NEXTROAD_USER");
    },
    openUserCampaignCreationModal() {
      this.isOpenedUserCampaignCreateModale = true;
    },
    openUserCampaignDeleteModale(user) {
      this.currentUser = user.customer;
      this.isOpenedUserRemoveModale = true;
    },
  },
  computed: {
    customerOrganization() {
      const mainCustomer = this.members.find((member) =>
        member.roles.includes("ROLE_CUSTOMER_MAIN")
      );
      return mainCustomer?.agency?.organization;
    },
  },
  mounted() {
    this.refreshViewData();
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.campaign_administration {
  padding: 15px;
  min-height: 100vh;

  .d-flex,
  .d-flex .left {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .d-flex {
    justify-content: space-between;
  }
  .actionBtn {
    cursor: pointer;
    margin: 5px;
  }
  .TableCard {
    margin: 25px 0;
    .v-data-footer .v-btn > .v-btn__content .v-icon {
      color: rgb(150, 150, 150) !important;
    }

    .search-field {
      margin-right: 10px;
      width: 24%;
    }
    .search-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .add-field {
      display: flex;
      flex-flow: row-reverse;
      flex-grow: 1;
    }

    .table-actions {
      display: flex;
      justify-content: center;
      align-items: center;

      .table-action {
        cursor: pointer;
        margin: 5px;
      }
    }
  }
  .search-row {
    height: 36px;
  }
}
</style>
