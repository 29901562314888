<template>
  <div class="EditPopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Modifier un utilisateur</h3>
      </v-card-title>
      <v-form ref="form" class="form" v-model="isValidForm" @submit.prevent="confirmModifications()">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              autofocus
              tabindex="1"
              outlined
              clearable
              label="Prénom"
              v-model="newUser.firstname"
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="2"
              label="Nom"
              outlined
              clearable
              :rules="[$rules.required]"
              v-model="newUser.lastname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="3"
              outlined
              clearable
              label="Email"
              :rules="[$rules.required, $rules.validEmail]"
              v-model="newUser.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" v-if="isInternal">
            <v-autocomplete
              label="Métier"
              tabindex="4"
              outlined
              clearable
              v-if="newUser.isInternal"
              :disabled="!canChangeJob"
              :items="jobsList"
              item-text="fr"
              item-value="en"
              v-model="newUser.job"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-autocomplete
              tabindex="5"
              outlined
              label="Rôles"
              clearable
              :items="rolesList"
              item-text="fr"
              item-value="en"
              multiple
              :rules="[$rules.atLeastOneRole]"
              small-chips
              deletable-chips
              v-model="newUser.roles"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              tabindex="6"
              outlined
              label="Agence"
              clearable
              :items="agencies"
              item-value="id"
              :rules="[$rules.required]"
              item-text="name"
              v-model="newUser.agency.id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="7"
              outlined
              clearable
              :rules="[rules.oneFilled]"
              @input="$refs.form.validate()"
              label="Téléphone"
              v-model="newUser.contact.phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="8"
              outlined
              clearable
              label="Mobile"
              :rules="[rules.oneFilled]"
              @input="$refs.form.validate()"
              v-model="newUser.contact.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <p v-if="isRoleAbove">
          Vous ne pouvez pas donner un rôle plus puissant que le votre, ni
          modifier un utilisateur plus haut dans la hiérarchie.
        </p>
      <v-card-actions>
        <MediumButtonSlot @click="closePopin"> Annuler </MediumButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot _type="submit" :_disabled="isRoleAbove || !isValidForm" @click="confirmModifications">
          Valider
        </HighButtonSlot>
      </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      newUser: { ...this.user },
      isInternal:
        this.user?.agency?.organization?.isInternal || this.user.job.length,
      isValidForm: true,
      rules: {
        oneFilled: () => {
          return (
            this.newUser.contact?.phone?.length === 10 ||
            this.newUser.contact?.mobile?.length === 10 ||
            "Au moins un numéro de téléphone requis"
          );
        },
      },
      internalRoles: [
        { en: "ROLE_ADMIN", fr: this.$t("ROLE_ADMIN") },
        { en: "ROLE_SUPER_ADMIN", fr: this.$t("ROLE_SUPER_ADMIN") },
        { en: "ROLE_NEXTROAD_ADMIN", fr: this.$t("ROLE_NEXTROAD_ADMIN") },
        { en: "ROLE_NEXTROAD_MAIN", fr: this.$t("ROLE_NEXTROAD_MAIN") },
        { en: "ROLE_NEXTROAD_USER", fr: this.$t("ROLE_NEXTROAD_USER") },
      ],
      externalRoles: [
        { en: "ROLE_CUSTOMER_USER", fr: this.$t("ROLE_CUSTOMER_USER") },
        { en: "ROLE_READ_ONLY", fr: this.$t("ROLE_READ_ONLY") },
        { en: "ROLE_CUSTOMER_MAIN", fr: this.$t("ROLE_CUSTOMER_MAIN") },
        {
          en: "ROLE_CUSTOMER_SUPERVISOR",
          fr: this.$t("ROLE_CUSTOMER_SUPERVISOR"),
        },
      ],
      jobsList: [
        { en: "technician", fr: this.$t("technician") },
        { en: "study_manager", fr: this.$t("study_manager") },
        { en: "commercial", fr: this.$t("commercial") },
        { en: "engineer", fr: this.$t("engineer") },
      ],
      agencies: [],
    };
  },
  computed: {
    rolesList() {
      return this.isInternal ? this.internalRoles : this.externalRoles;
    },
    isRoleAbove() {
      let hasRoleAbove = false;
      this.newUser.roles.forEach((role) => {
        hasRoleAbove = this.$isRoleAbove(role) ? true : hasRoleAbove;
      });
      return hasRoleAbove;
    },
    canChangeJob() {
      return !this.$isRoleAbove("ROLE_NEXTROAD_MAIN");
    },
  },
  methods: {
    async getAgencies() {
      this.agencies = await this.$api.agencies.findByOrganization(
        this.user.agency.organization.id
      );
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      await this.$api.users.edit(this.user.id, {
        ...this.newUser,
        agencyId: this.newUser.agency.id,
        mobile: this.newUser.contact.mobile,
        phone: this.newUser.contact.phone,
      });
      this.$emit("userEdited");
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
    this.getAgencies();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
