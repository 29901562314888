<template>
  <div class="CreatePopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Créer une agence</h3>
      </v-card-title>
      <v-form
        class="form"
        ref="form"
        v-model="isFormValid"
        @submit.prevent="confirmModifications"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              autofocus
              tabindex="1"
              outlined
              label="Nom*"
              :rules="[$rules.required]"
              clearable
              v-model="agency.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Email*"
              type="email"
              tabindex="2"
              clearable
              outlined
              v-model="agency.email"
              :rules="[$rules.required, $rules.validEmail]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="false === organization.isInternal">
            <v-autocomplete
              tabindex="3"
              outlined
              label="Commercial*"
              :items="commercials"
              clearable
              item-value="id"
              item-text="email"
              v-model="agency.commercialId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="4"
              outlined
              clearable
              :rules="[$rules.required]"
              label="Pays*"
              v-model="agency.country"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="5"
              outlined
              clearable
              label="Adresse*"
              placeholder="ex: 12 rue des lilas"
              :rules="[$rules.required]"
              v-model="agency.street"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="6"
              outlined
              label="Détail"
              clearable
              v-model="agency.detail"
            ></v-text-field>
          </v-col> </v-row
        ><v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="7"
              outlined
              clearable
              label="Code postal*"
              v-model="agency.zipCode"
              :rules="[$rules.validZipCode, $rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Ville*"
              tabindex="8"
              clearable
              outlined
              v-model="agency.city"
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="9"
              outlined
              label="Téléphone*"
              v-model="agency.phone"
              clearable
              @input="$refs.form.validate()"
              :rules="[rules.oneFilled]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              @input="$refs.form.validate()"
              tabindex="10"
              outlined
              label="Mobile*"
              :rules="[rules.oneFilled]"
              clearable
              v-model="agency.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-if="false === organization.isInternal">
            <v-autocomplete
              tabindex="11"
              outlined
              label="Système géoréférentiel*"
              :items="projectingSystems"
              item-text="name"
              item-value="val"
              v-model="agency.projectingSystem"
              clearable
              placeholder="L93"
            ></v-autocomplete>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-text-field
              tabindex="11"
              outlined
              label="Nom raccourci*"
              clearable
              v-model="agency.shortName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <editable-list-field
              v-model="agencyProviders"
              label="Prestataire(s)"
              :item-text="(item) => item.provider.name"
              :empty-item="agencyProvider"
              modale-title="Paramétrer le prestataire"
            >
              <template v-slot:modale="agencyProvider">
                <v-form>
                  <v-autocomplete
                    label="Prestataire"
                    :items="providers"
                    item-text="name"
                    :item-value="(item) => item"
                    v-model="agencyProvider.newItem.provider"
                    clearable
                    tabindex="100"
                    autofocus
                    outlined
                    :rules="[$rules.required]"
                  >
                  </v-autocomplete>
                  <v-text-field
                    label="Nom utilisateur"
                    v-model="agencyProvider.newItem.username"
                    clearable
                    outlined
                    :rules="[$rules.required]"
                    tabindex="101"
                  ></v-text-field>
                  <v-text-field
                    label="Mot de passe"
                    v-model="agencyProvider.newItem.password"
                    clearable
                    :rules="[$rules.required]"
                    outlined
                    tabindex="102"
                  ></v-text-field>
                </v-form>
              </template>
            </editable-list-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot _type="submit" :_disabled="!isFormValid">
            Valider
          </HighButtonSlot>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["organization"],
  data() {
    return {
      isFormValid: false,
      agency: {
        name: "",
        organizationId: this.organization.id,
        commercialId: null,
        street: "",
        detail: "",
        zipCode: "",
        city: "",
        country: "",
        email: "",
        mobile: "",
        phone: "",
        shortName: null,
        projectingSystem: this.organization.isInternal ? null : "L93",
      },
      agencyProvider: {
        id: null,
        username: "",
        password: "",
        agency: {
          id: null,
          name: null,
        },
        provider: {
          id: null,
          name: "bob",
        },
      },
      providers: [],
      agencyProviders: [],
      rules: {
        oneFilled: () => {
          return (
            this.agency.phone.length === 10 ||
            this.agency.mobile.length === 10 ||
            "au moins un numéro de téléphone requis"
          );
        },
      },
      commercials: [],
      projectingSystems: [
        {
          name: "World Geodetic system 1984",
          val: "WGS84",
        },
        {
          name: "Lambert 93",
          val: "L93",
        },
        {
          name: "Conique conforme 44",
          val: "CC44",
        },
        {
          name: "Conique conforme 45",
          val: "CC45",
        },
        {
          name: "Conique conforme 49",
          val: "CC49",
        },
      ],
    };
  },
  methods: {
    async getProviders() {
      this.providers = await this.$api.providers.findAll();
    },
    refreshModale() {
      this.$emit("refreshModale");
    },
    isValidForm() {
      return (
        this.agency.name.length > 2 &&
        this.agency.street.length > 2 &&
        this.agency.zipCode.length > 2 &&
        this.agency.country.length > 0 &&
        (this.agency.phone.length === 10 || this.agency.mobile.length === 10) &&
        this.agency.email.length > 0
      );
    },
    async getCommercials() {
      this.commercials = await this.$api.users.findByJob("commercial");
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      if (!this.isValidForm()) return console.error("invalid form");

      const agency = await this.$api.agencies.create(this.agency);
      this.$emit("agencyCreated", agency);

      this.agencyProviders.forEach(async (ap) => {
        await this.$api.agencies.addProviderToAgency(agency.id, ap.provider.id, ap);
      });

      this.closePopin();
    },
  },
  mounted() {
    this.getProviders();
    this.getCommercials();
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.CreatePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
