var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign_administration"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left"},[_c('v-btn',{staticClass:"ml-2 mr-2 m_brown",attrs:{"icon":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-left-bold-circle")])],1),_c('div',[_c('h1',[_vm._v("Administration de "+_vm._s(_vm.$cap(_vm.campaign.name)))])])],1)])]),_c('v-card',{staticClass:"TableCard",attrs:{"flat":""}},[_c('v-card-title',[_c('h3',[_vm._v("Membres")])]),_c('v-card-text',[_c('div',{staticClass:"search-row"},[_c('div',{staticClass:"search-field"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search_member'),"single-line":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.memberSearch),callback:function ($$v) {_vm.memberSearch=$$v},expression:"memberSearch"}})],1),_c('div',{staticClass:"add-field"},[(_vm.$hasRight('campaigns.addMember'))?_c('medium-button-slot',{on:{"click":function($event){return _vm.openUserCampaignCreationModal()}}},[_vm._v(_vm._s(_vm.$t("add_member")))]):_vm._e()],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.members === [],"headers":_vm.membersHeaders,"items":_vm.members,"items-per-page":10,"search":_vm.memberSearch,"hide-default-footer":_vm.members.length < 11},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[(_vm.$hasRight('users.edit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#e89005"},on:{"click":function($event){return _vm.openUserEditModale(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pen")])]}}],null,true)},[_c('span',[_vm._v("Modifier l'utilisateur")])]):_vm._e(),(_vm.$hasRight('campaigns.removeMember'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#b80000"},on:{"click":function($event){return _vm.openUserCampaignDeleteModale(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-minus")])]}}],null,true)},[_c('span',[_vm._v("Retirer de la campagne")])]):_vm._e()],1)]}},{key:"item.isTeamMember",fn:function(ref){
var item = ref.item;
return [(_vm.$hasRight('campaigns.updateUserRights'))?_c('v-checkbox',{attrs:{"color":"#2a7221"},on:{"change":function($event){return _vm.updateUser(item)}},model:{value:(item.isTeamMember),callback:function ($$v) {_vm.$set(item, "isTeamMember", $$v)},expression:"item.isTeamMember"}}):_vm._e()]}},{key:"item.readOnly",fn:function(ref){
var item = ref.item;
return [(_vm.$hasRight('campaigns.updateUserRights'))?_c('v-checkbox',{attrs:{"color":"#2a7221"},on:{"change":function($event){return _vm.updateUser(item)}},model:{value:(item.readOnly),callback:function ($$v) {_vm.$set(item, "readOnly", $$v)},expression:"item.readOnly"}}):_vm._e()]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isMainCustomer(item.customer) ? "Client principal" : _vm.$t(item.customer.job))+" ")]}}])})],1)],1),(_vm.isOpenedUserCampaignCreateModale)?_c('user-campaign-creation-modale',{attrs:{"campaign":_vm.campaign,"organization":_vm.customerOrganization,"members":_vm.members},on:{"closePopin":function($event){_vm.isOpenedUserCampaignCreateModale = false},"userAdded":function($event){return _vm.refreshViewData()}}}):_vm._e(),(_vm.isOpenedUserRemoveModale)?_c('RemoveUserModale',{attrs:{"user":_vm.currentUser},on:{"userDeleted":_vm.refreshViewData,"closePopin":function($event){_vm.isOpenedUserRemoveModale = false}}}):_vm._e(),(_vm.isOpenedUserEditModale)?_c('EditUserModale',{attrs:{"user":_vm.currentUser},on:{"closePopin":function($event){_vm.isOpenedUserEditModale = false},"userEdited":_vm.refreshViewData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }