<template>
  <div class="EditPopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Créer un utilisateur</h3>
      </v-card-title>
      <v-form ref="form" class="form" @submit.prevent="confirmModifications()">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              autofocus
              tabindex="1"
              outlined
              label="Prénom*"
              clearable
              v-model="newUser.firstname"
              :rules="[$rules.required, $rules.minChars]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Nom*"
              :rules="[$rules.required, $rules.minChars]"
              tabindex="2"
              clearable
              outlined
              v-model="newUser.lastname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="3"
              :rules="[$rules.required, $rules.validEmail]"
              outlined
              clearable
              type="email"
              label="Email*"
              v-model="newUser.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" v-if="organization.isInternal">
            <v-autocomplete
              label="Métier*"
              tabindex="4"
              outlined
              clearable
              :items="jobsList"
              item-text="fr"
              :rules="[$rules.required]"
              item-value="en"
              v-model="newUser.job"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-autocomplete
              outlined
              tabindex="5"
              label="Rôles*"
              :items="rolesList"
              item-value="en"
              item-text="fr"
              :rules="[$rules.atLeastOneRole]"
              clearable
              multiple
              small-chips
              deletable-chips
              v-model="newUser.roles"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              tabindex="6"
              outlined
              label="Agence*"
              :items="agencies"
              :rules="[$rules.required]"
              clearable
              item-value="id"
              item-text="name"
              v-model="newUser.agency.id"
            >
            </v-autocomplete>
            <HighButtonSlot @click="isAgencyCreateModaleOpened = true"
              >Nouvelle agence</HighButtonSlot
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="7"
              outlined
              label="Téléphone*"
              v-model="newUser.phone"
              @input="$refs.form.validate()"
              :rules="[rules.oneFilled]"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="8"
              outlined
              label="Mobile*"
              :rules="[rules.oneFilled]"
              @input="$refs.form.validate()"
              clearable
              v-model="newUser.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <p v-if="isRoleAbove">
          Vous ne pouvez pas donner un rôle plus puissant que le votre, ni
          modifier un utilisateur plus haut dans la hiérarchie.
        </p>
        <v-card-actions>
          <MediumButtonSlot @click="closePopin"> Annuler </MediumButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot
            _type="submit"
            :_disabled="isRoleAbove || !isValidForm()"
          >
            Valider
          </HighButtonSlot>
        </v-card-actions>
      </v-form>
    </v-card>
    <AgencyCreateModale
      v-if="isAgencyCreateModaleOpened"
      :organization="organization"
      @closePopin="isAgencyCreateModaleOpened = false"
      @agencyCreated="agencyCreatedHandler"
    />
  </div>
</template>

<script>
import AgencyCreateModale from "@/components/entities/agency/CreateAgency.modal.vue";

export default {
  components: {
    AgencyCreateModale,
  },
  props: ["organization"],
  data() {
    return {
      isAgencyCreateModaleOpened: false,
      rules: {
        oneFilled: () => {
          return (
            this.newUser.phone.length === 10 ||
            this.newUser.mobile.length === 10 ||
            "Au moins un numéro de téléphone requis"
          );
        },
      },
      newUser: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        phone: "",
        job: "",
        isInternal: this.organization.isInternal,
        roles: [],
        agency: {
          id: 0,
        },
      },
      internalRoles: [
        { en: "ROLE_ADMIN", fr: this.$t("ROLE_ADMIN") },
        { en: "ROLE_SUPER_ADMIN", fr: this.$t("ROLE_SUPER_ADMIN") },
        { en: "ROLE_NEXTROAD_ADMIN", fr: this.$t("ROLE_NEXTROAD_ADMIN") },
        { en: "ROLE_NEXTROAD_MAIN", fr: this.$t("ROLE_NEXTROAD_MAIN") },
        { en: "ROLE_NEXTROAD_USER", fr: this.$t("ROLE_NEXTROAD_USER") },
      ],
      externalRoles: [
        { en: "ROLE_CUSTOMER_USER", fr: this.$t("ROLE_CUSTOMER_USER") },
        { en: "ROLE_READ_ONLY", fr: this.$t("ROLE_READ_ONLY") },
        { en: "ROLE_CUSTOMER_MAIN", fr: this.$t("ROLE_CUSTOMER_MAIN") },
        {
          en: "ROLE_CUSTOMER_SUPERVISOR",
          fr: this.$t("ROLE_CUSTOMER_SUPERVISOR"),
        },
      ],
      jobsList: [
        { en: "technician", fr: this.$t("technician") },
        { en: "study_manager", fr: this.$t("study_manager") },
        { en: "commercial", fr: this.$t("commercial") },
        { en: "engineer", fr: this.$t("engineer") },
      ],
      agencies: [],
    };
  },
  computed: {
    rolesList() {
      if (this.organization.isInternal) return this.internalRoles;
      else return this.externalRoles;
    },
    isRoleAbove() {
      let hasRoleAbove = false;
      this.newUser.roles.forEach((role) => {
        hasRoleAbove = this.$isRoleAbove(role) ? true : hasRoleAbove;
      });
      return hasRoleAbove;
    },
  },
  methods: {
    isValidForm() {
      return (
        this.newUser.firstname.length > 2 &&
        this.newUser.lastname.length > 2 &&
        this.newUser.email.length > 2 &&
        this.newUser.agency.id > 0 &&
        (this.newUser.phone.length === 10 ||
          this.newUser.mobile.length === 10) &&
        this.newUser.roles.length > 0
      );
    },
    getUserType() {
      const userRoles = this.newUser.roles;

      if (userRoles.includes("ROLE_SUPER_ADMIN")) return "super_admin";
      if (userRoles.includes("ROLE_ADMIN")) return "administration";
      if (userRoles.includes("ROLE_NEXTROAD_ADMIN")) return "nextroad_admin";
      if (userRoles.includes("ROLE_NEXTROAD_MAIN")) return "nextroad_main";
      if (userRoles.includes("ROLE_NEXTROAD_USER")) return "nextroad";
      if (userRoles.includes("ROLE_CUSTOMER_SUPERVISOR"))
        return "customer_supervisor";
      if (userRoles.includes("ROLE_CUSTOMER_MAIN")) return "customer_main";
      if (userRoles.includes("ROLE_CUSTOMER_USER")) return "customer";
      else return "read_only";
    },
    async getAgencies() {
      this.agencies = await this.$api.agencies.findByOrganization(
        this.organization.id
      );
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      if (!this.isValidForm()) return console.error("invalid form");
      if (this.newUser.job === "") this.newUser.job = null;
      let user = await this.$api.users.create(
        {
          ...this.newUser,
          agencyId: this.newUser.agency.id,
          phone: this.newUser.phone,
          mobile: this.newUser.mobile,
          plainPassword: this.organization.id,
          userType: this.getUserType(),
        },
        // email not activated (https://github.com/Fogo-Capital/maorie-monolith/issues/947)
        false
      );
      this.$emit("userCreated", user);
      this.closePopin();
    },
    async agencyCreatedHandler(agency) {
      this.isAgencyCreateModaleOpened = false;
      await this.getAgencies();
      this.newUser.agency = agency;
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
    this.getAgencies();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
