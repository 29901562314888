<template>
  <div class="notFoundContainer">
    <page-not-found-vector class="vector" />
    <h1>La page n'a pas pu être trouvée</h1>

    <!-- DO NOT REMOVE -->
    <a class="imageLink" href="https://storyset.com/web"
      >Web illustrations by Storyset</a
    >
    <!-- DO NOT REMOVE -->
    
  </div>
</template>

<script>
import PageNotFoundVector from "@/components/errors/PageNotFoundVector";

export default {
  components: {
    PageNotFoundVector,
  },
};
</script>

<style lang="scss" scoped>
.notFoundContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .vector {
    width: 30vw;
  }

  .imageLink {
    color: gray;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
